<template>
  <component :is="tag" :type="tag === 'button' ? nativeType : ''" :disabled="disabled || loading" @click="handleClick"
    class="btn" :class="[
    { 'btn-round': round },
    { 'btn-block': block },
    { 'btn-wd': wide },
    { 'btn-icon btn-fab': icon },
    { [`btn-${type}`]: type },
    { [`btn-${size}`]: size },
    { 'btn-simple': simple },
    { 'btn-link': link },
    { disabled: disabled && tag !== 'button' }
  ]">
    <slot name="loading">
      <i v-if="loading" class="fas fa-spinner fa-spin"></i>
    </slot>
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'base-button',
  props: {
    tag: {
      type: String,
      default: 'button',
      description: 'Button html tag'
    },
    round: Boolean,
    icon: Boolean,
    block: Boolean,
    loading: Boolean,
    wide: Boolean,
    disabled: Boolean,
    type: {
      type: String,
      default: 'default',
      description: 'Button type (primary|secondary|danger etc)'
    },
    nativeType: {
      type: String,
      default: 'button',
      description: 'Button native type (e.g button, input etc)'
    },
    size: {
      type: String,
      default: '',
      description: 'Button size (sm|lg)'
    },
    simple: {
      type: Boolean,
      description: 'Whether button is simple (outlined)'
    },
    link: {
      type: Boolean,
      description: 'Whether button is a link (no borders or background)'
    }
  },
  methods: {
    handleClick(evt) {
      this.$emit('click', evt);
    }
  }
};
</script>
<style scoped lang="scss">
.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  i {
    padding: 0 3px;
  }
}

.btn-outlined {
  background: #1A1A1A;
  color: #fff;
  border: 1px solid;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  height: 40px;
  padding: 14px;
  text-align: center;
  font-weight: bold;
  min-height: 42px;
}

.btn-outlined:hover,
.btn-outlined:focus,
.btn-outlined:active,
.btn-outlined:visited {
  background: #F2F4F6 !important;
  border: 1px solid #004583;
  color: #004583;
}
</style>
