<template>
  <SlideYUpTransition :duration="animationDuration">
    <div ref="modal" class="modal fade" @click.self="closeModal" :class="[
      { 'show d-block': show },
      { 'd-none': !show },
      { 'modal-mini': type === 'mini' }
    ]" v-show="show" tabindex="-1" role="dialog" :aria-hidden="!show">
      <div class="modal-dialog" role="document" :class="[
        { 'modal-notice': type === 'notice' },
        { 'modal-large': type === 'large' },
        { 'modal-dialog-centered': centered },
        modalClasses
      ]">
        <div class="modal-content" :class="[
          gradient ? `bg-gradient-${gradient}` : '',
          modalContentClasses
        ]">
          <div class="modal-header" :class="[headerClasses]" v-if="$slots.header">
            <slot name="header"></slot>
            <slot name="close-button">
              <button type="button" class="close" v-if="showClose" @click="closeModal" data-dismiss="modal"
                aria-label="Close">
                <i class="fa-solid fa-circle-xmark"></i>
              </button>
            </slot>
          </div>

          <div v-if="$slots.default" class="modal-body" :class="bodyClasses">
            <slot></slot>
          </div>

          <div class="modal-footer" :class="footerClasses" v-if="$slots.footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
      <div class="close-on-top" v-if="showCloseOnTop">
            <button type="button" class="close" @click="closeModal" data-dismiss="modal" aria-label="Close">
              <i class="close-on-top__icon"></i>
            </button>
          </div>
    </div>
  </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  name: 'modal',
  components: {
    SlideYUpTransition
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true
    },
    showCloseOnTop: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: true,
      description: 'Whether modal should be appended to document body'
    },
    scrollToBottom: {
      type: Boolean,
      default: true,
      description: "Whether modal should scroll to it's bottom automatically"
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        let acceptedValues = ['', 'large', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Modal type (notice|mini|large|"") '
    },
    modalClasses: {
      type: [Object, String],
      description: 'Modal dialog css classes'
    },
    modalContentClasses: {
      type: [Object, String],
      description: 'Modal dialog content css classes'
    },
    gradient: {
      type: String,
      description: 'Modal gradient type (danger, primary etc)'
    },
    headerClasses: {
      type: [Object, String],
      description: 'Modal Header css classes'
    },
    bodyClasses: {
      type: [Object, String],
      description: 'Modal Body css classes'
    },
    footerClasses: {
      type: [Object, String],
      description: 'Modal Footer css classes'
    },
    animationDuration: {
      type: Number,
      default: 500,
      description: 'Modal transition duration'
    }
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
    scrollModalToBottom() {
      if (!this.scrollToBottom) return;
      let elm = this.$refs.modal;
      elm.scrollTop = elm.scrollHeight - elm.clientHeight;
    }
  },
  mounted() {
    if (this.appendToBody) {
      document.body.appendChild(this.$el);
    }
  },
  destroyed() {
    if (this.appendToBody && this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
  },
  watch: {
    show(val) {
      let documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
        this.$nextTick(this.scrollModalToBottom);
      } else {
        documentClasses.remove('modal-open');
      }
    }
  }
};
</script>
<style scoped>
.modal.show {
  background-color: rgba(0, 0, 0, 0.3);
}

.modal-large{
  max-width: 85% !important;
  transform: translate(0, 50px) !important;
}

.modal-large .modal-body {
  padding: 0;
}

.close {
  color: var(--grey-999999, #999) !important;
}

.close-on-top {
  position: fixed;
  top: 0;
  right: 0;
  margin: 20px;
  border-radius: 1000px;
  border: 1px solid var(--grey-999999, #999);
  background: var(--white-ffffff, #FFF);
  display: flex;
  width: 42px;
  height: 42px;
  padding: 0px 14px;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.close-on-top__icon {
  color: var(--grey-999999, #999);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.close-on-top__icon::before {
  font-family: "Font Awesome 6 Pro";
  color: #999999;
  font-size: 25px;
  display: flex;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  content: "\f00d";
}
</style>
