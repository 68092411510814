import Vue from 'vue';
import axios from "@/axios";
import router from "@/routes/router";
import { Message } from "element-ui";
import { apiHandler } from "@/util/errorHandling";
import { getCookie, setCookie, deleteCookie, cookies } from '@/util/cookies';

const getDefaultState = () => {
  return {
    hasWorkspace: false,
    user: null,
    userRole: null,
    token: null,
    jobRoles: null,
    isUserImpersonated: false,
    isUserInfoFetched: false,
  };
};

const moduleUser = {
  state: getDefaultState(),
  getters: {
    getToken(state) {
      return state.token;
    },
    getUserExtraInfo(state) {
      return state.user.extra_info ?? {};
    },
    getUser(state) {
      return state.user;
    },
    getHasWorkspace(state) {
      return state.hasWorkspace;
    },
    hasAvatar(state) {
      return Boolean(state.user.profile_photo_path);
    },
    getAvatar(state) {
      return (
        state.user?.profile_photo_path ??
        process.env.BASE_URL + "img/empty_profile_placeholder.png"
      );
    },
    getJobRoles(state) {
      return state.jobRoles;
    },
    isUserLoggedIn(state) {
      return state.user !== null;
    },
    getUserRole(state) {
      return state.userRole;
    },
    isUserMaster(state, getters) {
      if (getters.getUserRole) {
        return getters.getUserRole === "master";
      }
      return null;
    },
    getIsUserImpersonated(state) {
      return state.isUserImpersonated;
    },
    getIsUserInfoFetched(state) {
      return state.isUserInfoFetched;
    },
  },
  mutations: {
    clearModuleUser(state) {
      Object.assign(state, getDefaultState());
    },
    setToken(state, token) {
      state.token = token;
    },
    setUser(state, user) {
      state.user = user;
    },
    setHasWorkspace(state, hasWorkspace) {
      state.hasWorkspace = hasWorkspace;
    },
    setJobRoles(state, jobRoles) {
      state.jobRoles = jobRoles;
    },
    setUserRole(state, userRole) {
      state.userRole = userRole;
    },
    clearAuthCookies() {
      deleteCookie(cookies.COOKIE_TOKEN_KEY);
      deleteCookie(cookies.COOKIE_USER_KEY);
      deleteCookie(cookies.COOKIE_IMPERSONATE_USER_KEY);
      deleteCookie(cookies.COOKIE_IMPERSONATE_TOKEN_KEY);
    },
    setIsUserInfoFetched(state, isUserInfoFetched) {
      state.isUserInfoFetched = isUserInfoFetched;
    },
    clearWorkspaceCookies() {
      console.log("clearWorkspaceCookies");
      deleteCookie(cookies.COOKIE_WORKSPACE);
    }
  },
  actions: {
    /**
     * Fetch the user from session or local storage and update Vuex store if available.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.state - Vuex state object.
     * @param {Object} context.commit - Vuex commit function.
     */
    fetchUser({ state, commit }) {
      // Check if the user is already in the state
      if (state.user) {
        return;
      }

      // Get impersonated user (if existing)
      let user = getCookie(cookies.COOKIE_IMPERSONATE_USER_KEY);
      state.isUserImpersonated = true;

      if (!user) {
        user = getCookie(cookies.COOKIE_USER_KEY);
        state.isUserImpersonated = false;
      }

      if (user) {
        commit("setUser", user);
      }
    },

    /**
     * Fetch the bearer token from cookies and update Vuex store if available.
     *
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.state - Vuex state object.
     * @param {Object} context.commit - Vuex commit function.
     */
    fetchToken({ state, commit }) {
      // Check if the token is already in the state
      if (state.token) {
        return;
      }

      // Get impersonated user token (if existing)
      let token = getCookie(cookies.COOKIE_IMPERSONATE_TOKEN_KEY);
      state.isUserImpersonated = true;

      if (!token) {
        token = getCookie(cookies.COOKIE_TOKEN_KEY);
        state.isUserImpersonated = false;
      }

      if (token) {
        commit("setToken", token);
      }
    },

    /**
     * Fetch user information from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchUserInfo({ getters, commit }) {
      const user = getters.getUser;

      const response = await axios.get("tools/show_user_info", {
        params: {
          user_id: user.id,
        },
      });

      // Update Vuex store with user role and workspace information
      commit("setUser", response.data.user_info);
      commit("setJobRoles", response.data.job_roles);
      commit("setUserRole", response.data.user_info.role);
      commit("setHasWorkspace", response.data.user_info.workspace);
      commit("setTools", response.data.templates);
      commit("setToolsCategories", response.data.categories);
      commit("setIsUserInfoFetched", true);
      let userIdentifiedData = {
        user_id : user.id,
        email: user.email,
        full_name: user.name,
        signup_utm_source: user.signup_utm_source,
        signup_utm_medium: user.signup_utm_medium,
        signup_utm_campaign: user.signup_utm_campaign,
        signup_utm_content: localStorage.getItem("utm_content"),
        country: user.country,
        customer_type: user.customer_type,
        industry: user.job_role,
      }
      window.gtm.user_identified(userIdentifiedData);
    },

    /**
     * Perform user login by sending credentials to the authentication service.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.commit - Vuex commit function.
     * @param {Object} context.dispatch - Vuex dispatch function.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} params - Login parameters including email, password, and remember_me.
     * @param {string} params.email - User email for login.
     * @param {string} params.password - User password for login.
     * @param {boolean} params.remember_me - Flag indicating whether to remember the user.
     */
    async login({ commit, dispatch, getters }, params) {
            const response = await axios.post("services/auth/login", {
              email: params.email,
              password: params.password,
            });


            const user = { id: response.data.user.id };
            const token = response.data["service-api"];

            if (params.remember_me) {
              setCookie(cookies.COOKIE_TOKEN_KEY, token, 24 * 365);
              setCookie(cookies.COOKIE_USER_KEY, user, 24 * 365);
            } else {
              setCookie(cookies.COOKIE_TOKEN_KEY, token);
              setCookie(cookies.COOKIE_USER_KEY, user);
            }

            // Fetch user and token information
            dispatch("fetchUser");
            dispatch("fetchToken");
            window.gtm.login(response.data.user ?? []);

            // Redirect to the home page after successful login
            router.push("/");
            console.log(response, 'response');
            return response;
    },

    /**
     * Request to reset user password and send a reset link to the provided email.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for requesting password reset.
     * @param {string} params.email - User email for password reset.
     */
    async rememberPassword(context, params) {
      await apiHandler(async () => {
        const response = await axios.post("services/auth/remember_password", params);
        console.log('rememberPassword', response);

        return response;
      });
    },

    /**
     * Log out the user by clearing cookies, and resetting the Vuex store state.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.commit - Vuex commit function.
     */
    async logout({ commit, dispatch }) {
      // Clear auth cookies
      commit("clearAuthCookies");
      commit("clearWorkspaceCookies");

      // Redirect to the login page after logout (and empty the store's state)
      window.location.replace("/login");
    },

    /**
     * Update user profile
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for update profile.
     * @param {string} params.name - User name
     * @param {string} params.job_role_id - User job id
     */
    async updateProfile(context, params) {
      await apiHandler(async () => {
        const response = await axios.post("tools/update_user_info", params);
        console.log('updateProfile', response);

        return response;
      });
    },

    /**
     * Change password
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for change password.
     * @param {string} params.current_password - Current password
     * @param {string} params.new_password - New password
     * @param {string} params.new_confirm_password - New confirm password
     */
    async changePassword(context, params) {
      await axios
        .post("services/auth/change_password", params)
        .then((response) => {
          Message({
            showClose: true,
            message: response.data.message,
            type: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
          Message({
            showClose: true,
            message:
              typeof error.response?.data?.message?.current_password != 'undefined' ? error.response?.data?.message?.current_password[0]
                : typeof error.response?.data?.message?.new_password != 'undefined' ? error.response?.data?.message?.new_password[0]
                  : typeof error.response?.data?.message?.new_confirm_password != 'undefined' ? error.response?.data?.message?.new_confirm_password[0]
                    : typeof error.response?.data?.message != 'undefined' ? error.response?.data?.message
                      : error.message,
            type: "error",
          });
          return error;
        });
    },

    /**
     * Reset password
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} params - Parameters for reset password.
     * @param {String} token - Token for reset password.
     * @param {string} params.password - New password
     * @param {string} params.password_confirmation - New confirm password
     */
    async resetPassword(context, params) {
      const response = await axios
        .post("services/auth/reset_password", { ...params })
        .then((response) => {
          Message({
            showClose: true,
            message: "Your password has been reset",
            type: "success",
          });
          setTimeout(() => {
            window.location.replace("/login");
          }, 1000);
        })
        .catch((error) => {
          Message({
            showClose: true,
            message:
              typeof error.response?.data?.message?.password !=
              "undefined"
                ? error.response?.data?.message?.password[0]
                : typeof error.response?.data?.message?.password_confirmation !=
                  "undefined"
                ? error.response?.data?.message?.password_confirmation[0]
                : typeof error.response?.data?.message != "undefined"
                ? error.response?.data?.message
                : error.message,
            type: "error",
          });
        });
      return response;
    },

    /**
     * @async
     * @param {Object} context - The Vuex context object.
     * @param {Object} params
     * @param {string} params.userId
     * @param {string} params.token
     * @throws {Error} If the parameters are not valid.
     */
    async impersonateUser(context, params) {
      // params validation
      if (typeof params !== 'object' || params === null) {
        throw new Error(`The given parameters are not valid.`);
      }
      // userId validation
      if (typeof parseInt(params?.userId) !== 'number' || isNaN(params?.userId)) {
        throw new Error(`The given userId is not valid.`);
      }
      // token validation
      if (typeof params?.token !== 'string' || params?.token === null) {
        throw new Error(`The given token is not valid.`);
      }

      const user = {
        id: params?.userId
      }

      // Set sessio cookies for the impersonated user
      document.cookie = `${cookies.COOKIE_IMPERSONATE_USER_KEY}=${JSON.stringify(user)}; path=/`;
      document.cookie = `${cookies.COOKIE_IMPERSONATE_TOKEN_KEY}=${JSON.stringify(params.token)}; path=/`;

      window.location.replace("/");
    },

    leaveImpersonate() {
      deleteCookie(cookies.COOKIE_IMPERSONATE_USER_KEY);
      deleteCookie(cookies.COOKIE_IMPERSONATE_TOKEN_KEY);
      window.location.replace("/");
    },
    async updateToken({ commit }){

      const response = await axios.post("tools/update_api_token");

      if(response && response.statusText.toLowerCase() === 'ok'){
        const token = response.data.plainTextToken

        if (token) {
          setCookie(cookies.COOKIE_TOKEN_KEY, token);
          commit("setToken", token);
          Vue.prototype.$message({
            message:
            'Token Refreshed successfully',
            type: 'success'
          });
        }
      } else {

        Vue.prototype.$message({
          message:
          'error updating token',
          type: 'error'
        });
      }
    }
  },
};

export default moduleUser;
