import axios from "@/axios";
import router from "@/routes/router";
import { apiHandler } from "@/util/errorHandling";
import { Message } from "element-ui";

const getDefaultState = () => {
  return {
    task: null,
    taskHistory: [],
    tasks: [],
    totalTasksNum: 0,
  };
};

const moduleTaskAssignment = {
  state: getDefaultState(),
  getters: {
    getTask(state) {
      return state.task;
    },
    getTaskHistory(state) {
      return state.taskHistory;
    },
    getTasks(state) {
      return state.tasks;
    },
    getTotalTasksNum(state) {
      return state.totalTasksNum;
    },
  },
  mutations: {
    clearModuleTasks(state) {
      Object.assign(state, getDefaultState());
    },
    setTask(state, task) {
      state.task = task;
    },
    setTaskHistory(state, taskHistory) {
      state.taskHistory = taskHistory;
    },
    setTasks(state, tasks) {
      state.tasks = tasks;
    },
    setTotalTasksNum(state, totalTasksNum) {
      state.totalTasksNum = totalTasksNum;
    },
  },
  actions: {
    async fetchTask(context, order_detail_id) {
      if (order_detail_id) {
        // Get the currently authenticated user
        const user = context.getters.getUser;

        if (user) {
          const queryParams = {
            user_id: user.id
          };

          // Fetch tasks based on the specified task type
          let response;
          response = await axios.get("tools/task_assignments/" + order_detail_id, {
            params: queryParams,
          });

          const task = response.data.taskAssignment;
          //const taskHistory = response.data.taskHistory;

          // If orders are retrieved, process and store the first order
          context.commit("setTask", task);
          context.dispatch("fetchTaskHistory", { task_id: task.id });
          return;
          
        }
      }

      // Throw an error if the required parameters are not provided or if the task retrieval process fails
      throw new Error("Failed to fetch task");
    },

    async fetchTasks({ getters, commit }, params = {}) {
      // Get the currently authenticated user
      const user = getters.getUser;

      if (user) {
        const queryParams = {
          user_id: user.id,
          workspace_id: getters.selectedWorkspace?.id,
          ...params,
        };

        // Fetch tasks based on the specified task type
        let response;
        response = await axios.get("tools/task_assignments", {
          params: queryParams,
        });

        if (response) {
          const totalTasksNum = response.data.recordsTotal;
          const tasks = response.data.taskAssignments;

          // Update Vuex store with fetched tasks and total count
          commit("setTasks", tasks);
          commit("setTotalTasksNum", totalTasksNum);

          return;
        }
      }

      throw new Error("Failed to fetch tasks");
    },

    async updateTask({ getters, commit, dispatch }, params) {
      await apiHandler(async () => {
        const user = getters.getUser;
        params.user_id = user.id;

        const response = await axios.patch("tools/task_assignments/" + params.order_detail_id, params);
        commit("setTask", response.data.taskAssignment);
        dispatch("fetchTaskHistory", { task_id: response.data.taskAssignment.id });

        return response;
      });
    },

    async fetchTaskHistory(context, params) {
      if (params.task_id) {
        // Get the currently authenticated user
        const user = context.getters.getUser;

        if (user) {
          const queryParams = {
            user_id: user.id,
            ...params,
          };

          // Fetch tasks based on the specified task type
          let response;
          response = await axios.get("tools/task_assignments/history/" + params.task_id, {
            params: queryParams,
          });

          const taskHistory = response.data.taskHistory;

          // If orders are retrieved, process and store the first order
          context.commit("setTaskHistory", taskHistory);
          return;
          
        }
      }

      // Throw an error if the required parameters are not provided or if the task retrieval process fails
      throw new Error("Failed to fetch task");
    },

  },
};

export default moduleTaskAssignment;
