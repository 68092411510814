import { Message } from "element-ui";

export { apiHandler, apiCreateOrderHandler };

const apiHandler = async (fn) => {
  try {
    const response = await fn();

    if (response?.data?.status === "success") {
      Message({
        showClose: true,
        message: response?.data?.message ?? response?.data?.msg ?? "Success",
        type: "success",
      });
      return response;
    }
    return null
  } catch (error) {
    console.log("Error", error);
    Message({
      showClose: true,
      message: error?.response?.data?.message ?? error?.response?.data?.msg ?? error?.message ?? "Generic error",
      type: "error",
    });
    return null;
  }
};

const apiCreateOrderHandler = async (fn, params) => {
  try {
    const response = await fn();

    if (response?.data?.status === "success") {
      return response;
    }

    return null;
  } catch (error) {
    console.log("Error", error);
    Message({
      showClose: true,
      message: error?.response?.data?.message ?? error?.response?.data?.msg ?? error?.message ?? "Generic error",
      type: "error",
    });
    window.gtm.tool_error(
      params?.gtag_service_name,
      params?.gtag_service_group,
      "Response is not success");
      return
  }
};
