<template>
  <div>
    <div class="topbar_prova">
      <h1 class="mb-0">Looking for the old Contents.com? It's Contents.ai now</h1>
      <a href="https://app.contents.ai/login">
        <base-button class="mt-3 w-100 btn__generate btn__filled" type="primary">
          Take me to Contents.ai
        </base-button>
      </a>
    </div>

    <div class="wrapper wrapper-full-page">
      <div class="full-page" :class="pageClass">
        <div class="content">
          <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
            <router-view></router-view>
          </zoom-center-transition>
        </div>
        <ContentFooter />
      </div>
    </div>
  </div>
</template>
<script>
// import { BaseNav } from 'src/components';
import { ZoomCenterTransition } from "vue2-transitions";
import ContentFooter from "src/pages/Layout/ContentFooter.vue";

export default {
  components: {
    // BaseNav,
    ZoomCenterTransition,
    ContentFooter,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    },
  },
  beforeDestroy() {
    this.closeMenu();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  mounted() {
    this.setPageClass();
  },
  watch: {
    $route() {
      this.setPageClass();
    },
  },
};
</script>
<style lang="scss">
.navbar.auth-navbar {
  top: 0;
}

$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}

.topbar_prova {
  background: linear-gradient(90deg, #1f4d95 0%, #401975 100%);
  height: 94px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid #9e6cfe;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: 160px;
    padding: 20px 0px;
  }

  h1 {
    color: #fff !important;
    @media screen and (max-width: 768px) {
      font-size: 20px;
      text-align: center;
    }
  }

  a {
    button {
      background-color: transparent;
      border: 1px solid #fff;

      &:hover {
        background-color: transparent !important;
        border: 1px solid #5a697d;
      }
    }
  }
}
</style>
