<template>
  <BreadCrumb v-if="tool?.macrocategory != 'ai_chat'">

    <!-- Dashboard -->
    <BreadCrumbItem style="display:inline-block">
      <router-link to="/dashboard" class="breadcrumb-link">
        {{ $t('sidebar.dashboard') }}
      </router-link>
    </BreadCrumbItem>

    <!-- Tool's category -->
    <BreadCrumbItem style="display:inline-block" :key="`categoryBreadcrumb-${componentKey}`">
      <router-link :to="`/tools?macrocategory=${tool?.macrocategory}`" class="breadcrumb-link">
        {{ $t(`toolsMacrocategories.${tool?.macrocategory}.title`) }}
      </router-link>
    </BreadCrumbItem>

    <!-- Tool -->
    <BreadCrumbItem style="display:inline-block" :key="`toolBreadcrumb-${componentKey}`">
      <span class="breadcrumb-current">{{ tool?.name }}</span>
    </BreadCrumbItem>

  </BreadCrumb>
</template>

<script>
import BreadCrumb from './Breadcrumb';
import BreadCrumbItem from './BreadcrumbItem';

export default {
  components: {
    BreadCrumb,
    BreadCrumbItem
  },
  data() {
    return {
      componentKey: 0
    }
  },
  computed: {
    tool() {
      const _this = this;
      const slug = this.$route.params.slug;
      return this.$store.getters.getTools.find((tool) => tool.slug === slug);
    }
  },
  watch: {
    tool() {
      this.componentKey++;
    }
  }
};
</script>
