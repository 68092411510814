export const googleTagManager = {

    login(user) {
        dataLayer.push({
            'event': 'login',
            'customer_type': user?.customer_type,
            'user_id': user?.id,
            'email': user?.email ?? '',
            'full_name': user?.name ?? '',
            'industry': user?.industry_name,
            'country': user?.country ?? '',
            'signup_utm_source': user?.utm_source ?? '',
            'signup_utm_medium': user?.utm_medium ?? '',
            'signup_utm_campaign': user?.utm_campaign ?? '',
        });
    },

    tool_viewed(data) {
        dataLayer.push({
            'event': 'tool_viewed',
            'tool_name': (data?.gtag_service_group ?? '') + ' - ' + (data?.gtag_service_name ?? ''),
            'tool_category': (data?.gtag_service_group ?? ''),
        });
    },

    tool_order_requested(data) {
        dataLayer.push({
            'event': 'tool_order_requested',
            'tool_name': (data?.gtag_service_group ?? '') + ' - ' + (data?.gtag_service_name ?? ''),
            'tool_category': (data?.gtag_service_group ?? ''),
            'cost_for_usage': (data?.human_credits ?? 0) * 0.01
        });
    },

    tool_order_completed(data) {
        dataLayer.push({
            'event': 'tool_order_completed',
            'tool_name': (data?.gtag_service_group ?? '') + ' - ' + (data?.gtag_service_name ?? ''),
            'tool_category': (data?.gtag_service_group ?? ''),
            'cost_for_usage': (data?.human_credits ?? 0) * 0.01
        });
    },

    tool_error(tool, category, error) {
        dataLayer.push({
            'event': 'tool_error',
            'tool_name': (category ?? '') + ' - ' + (tool ?? ''),
            'tool_category': (category ?? ''),
            'cost_for_usage': 0,
            'error_type': error ?? ''
        });
    },

    user_identified(data) {
        dataLayer.push({
            'event': 'user_identified',
            'user_id': data.user_id,
            'email': data.email ?? '',
            'full_name':data.full_name ?? '',
            'country': data.country ?? '',
            'customer_type': data.customer_type ?? '',
            'signup_utm_source': data.signup_utm_source ?? '',
            'signup_utm_medium': data.signup_utm_medium ?? '',
            'signup_utm_campaign': data.signup_utm_campaign ?? '',
            'signup_utm_content': data.signup_utm_content ?? '',
            'newsletter_subscriber':'NewsletterSubscriber' ?? '',
            'industry': data.industry ?? '',
       });
    }
};
