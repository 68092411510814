import axios from "@/axios";
import router from "@/routes/router";
import { Message } from "element-ui";
import { getCookie, setCookie, deleteCookie, cookies } from '@/util/cookies';
import { apiHandler } from "@/util/errorHandling";


const getDefaultState = () => {
  return {
    availableWorkspaces: 0,
    maxWorkspaces: 0,
    selectedWorkspace: {},
    workspaces: [],
    userWorkspaces: [],
    workspaceUsers: [],
    excludedWorkspaceUsers: []
  };
};

const moduleWorkspace = {
  state: getDefaultState(),
  getters: {
    getAvailableWorkspaces(state) {
      return state.availableWorkspaces;
    },
    getMaxWorkspaces(state) {
      return state.maxWorkspaces;
    },
    selectedWorkspace(state) {
      return state.selectedWorkspace
    },
    getWorkspaces(state) {
      return state.workspaces
    },
    userWorkspaces(state) {
      return state.userWorkspaces
    },
    workspaceUsers(state) {
      return state.workspaceUsers
    },
    excludedWorkspaceUsers(state) {
      return state.excludedWorkspaceUsers
    },
    inPersonalWorkspace(state, getters) {
      return getters.getHasWorkspace && !state.selectedWorkspace.id
    }
  },
  mutations: {
    clearModuleWorkspace(state) {
      deleteCookie(cookies.COOKIE_WORKSPACE);
      Object.assign(state, getDefaultState());
    },
    setAvailableWorkspaces(state, availableWorkspaces) {
      state.availableWorkspaces = availableWorkspaces;
    },
    setMaxWorkspaces(state, maxWorkspaces) {
      state.maxWorkspaces = maxWorkspaces;
    },
    setWorkspaces(state, workspaces) {
      state.workspaces = workspaces;
    },
    setSelectedWorkspace(state, workspace) {
      state.selectedWorkspace = workspace;
    },
    setUserWorkspaces(state, user_workspaces) {
      state.userWorkspaces = user_workspaces
    },
    setWorkspaceUsers(state, workspace_users) {
      state.workspaceUsers = workspace_users
    },
    setExcludedWorkspaceUsers(state, excluded_workspace_users) {
      state.excludedWorkspaceUsers = excluded_workspace_users
    },
    updateEditedWorkspace(state, newWorkspaceData) {
      state.workspaces.map(workspace => {
        if (workspace.id === newWorkspaceData.id) {
          workspace.name = newWorkspaceData.name;
        }
      })
    }
  },
  actions: {
    async fetchWorkspaces({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };
      const response = await axios.get("features/workspace/show", {
        params: params,
      });
      commit("setWorkspaces", response.data.workspace_list);
      commit('setAvailableWorkspaces', response.data.workspace_limit.available_workspaces);
      commit('setMaxWorkspaces', response.data.workspace_limit.max_workspaces);
    },

    /**
     * Fetch user's workspaces from the server and update Vuex store.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchUserWorkspaces({ getters, commit }) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
      };
      const response = await axios.get("features/workspace/my_workspace", {
        params: params,
      });
      commit("setUserWorkspaces", response.data.workspace_list);
      commit('setAvailableWorkspaces', response.data.workspace_limit.available_workspaces);
      commit('setMaxWorkspaces', response.data.workspace_limit.max_workspaces);
    },

    async fetchSelectedWorkspace({ commit }) {
      let workspace = getCookie(cookies.COOKIE_WORKSPACE);
      if (workspace) {
        commit("setSelectedWorkspace", workspace);
      }
    },

    /**
     * Save selected workspace in store and cookie
     *
     * @param {Object} context.commit - Vuex commit.
     * @param {number} workspace_id - The ID of the brand voice entry to be deleted.
     */
    async setWorkspace({commit}, workspace) {
      commit('clearModuleWorkspace')
      setCookie(cookies.COOKIE_WORKSPACE, workspace);
      commit("setSelectedWorkspace", workspace);
    },


    /**
     * Create new workspace
     *
     * @param {params.name} name - the name of the workspace.
     * @param {params.users} users - the users to be added.
     */
    async createWorkspace({ getters, dispatch }, params) {
      const user = getters.getUser;
      await axios.put(`features/workspace/create`, {
        user_id: user.id,
        name: params.name,
        /* users: params.users.map(u => u.user_id) */
      }).then((response) => {
        dispatch('addUserToWorkspace', {
          'id': response.data.id,
          'users': params.users
        });

      })
      .catch((error) => {
        console.log("error", error);
        Message({
          showClose: true,
          message: error.response?.data?.message,
          type: "error",
        });
        return error;
      });
    },

    /**
     * Add user to workspace
     *
     * @param {params.id} id - workspace ID.
     * @param {params.users} users - he users to be added.
     */
      async addUserToWorkspace({ getters }, params) {
        const user = getters.getUser;
        await axios.post(`features/workspace/add_users`, {
          user_id: user.id,
          id: params.id,
          users: params.users.map(u => u.user_id),
        })
        .catch((error) => {
          console.log("error", error);
          Message({
            showClose: true,
            message: error.response?.data?.message,
            type: "error",
          });
          return error;
        });
      },

    /**
     * Fetch user IN a workspace from the server.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchWorkspaceUsers({ getters, commit }, workspace_id) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
        id: workspace_id,
      };
      const response = await axios.get("features/workspace/users", {
        params: params,
      });

       commit("setWorkspaceUsers", response.data.response);
    },

    /**
     * Fetch user NOT IN a workspace from the server.
     *
     * @async
     * @param {Object} context - The Vuex action context.
     * @param {Object} context.getters - Vuex getters object.
     * @param {Object} context.commit - Vuex commit function.
     */
    async fetchExcludedWorkspaceUsers({ getters, commit }, workspace_id) {
      const user = getters.getUser;
      const params = {
        user_id: user.id,
        id: workspace_id,
        action: 'excluded'
      };
      const response = await axios.get("features/workspace/users", {
        params: params,
      });
       commit("setExcludedWorkspaceUsers", response.data.response);
    },

    /**
     * Add user to workspace
     *
     * @param {params.id} id - workspace ID.
     * @param {params.users} users - he users to be added.
     */
    async removeUserFromWorkspace({ getters }, params) {
      const user = getters.getUser;
      await axios.delete(`features/workspace/delete_users`, {
        data: {
          user_id: user.id,
          id: params.id,
          users: params.users.map(u => u.user_id)
        }
      })
      .catch((error) => {
        console.log("error", error);
        Message({
          showClose: true,
          message: error.response?.data?.message,
          type: "error",
        });
        return error;
      });
    },

    async deleteWorkspace({ getters, dispatch, commit }, id) {
      await apiHandler(async () => {
        const user = getters.getUser;
        const params = {
          user_id: user.id,
          id
        };

        const response = await axios.delete("features/workspace/delete", {
          params
        });
        console.log('deleteWorkspace', response);

        if (id === getters.selectedWorkspace.id) {
          deleteCookie(cookies.COOKIE_WORKSPACE);
          commit("setSelectedWorkspace", null);
          router.push({name: 'workspace.login'});
        }

        await dispatch('fetchUserWorkspaces');
        return response;
      });
    },

    async editWorkspace({ getters, commit }, params) {
      try {
        const response = await axios.put(`/features/workspace/${params.id}`, params);
        commit('updateEditedWorkspace', response.data);
        if(params.id === getters.selectedWorkspace.id) {
          commit("setSelectedWorkspace", response.data);
        }
        Message({
          showClose: true,
          message: "Workspace updated",
          type: "success",
        });
        return response.data
      } catch (error) {
        Message({
          showClose: true,
          message: error.response?.data?.message,
          type: "error",
        });
      }
    },

    async toggleBrandVoice({ getters }, params) {
      const user = getters.getUser;
      await axios.post(`features/workspace/toggle_brand_voice`, {
          user_id: user.id,
          id: params.id,
          brand_voice_id: params.brand_voice_id
      })
      .catch((error) => {
        console.log("error", error);
        Message({
          showClose: true,
          message: error.response?.data?.message,
          type: "error",
        });
        return error;
      });
    },

    async toggleSources({ getters }, params) {
      const user = getters.getUser;
      await axios.post(`features/workspace/toggle_additional_source`, {
          user_id: user.id,
          id: params.id,
          additional_source_id: params.additional_source_id
      })
      .catch((error) => {
        console.log("error", error);
        Message({
          showClose: true,
          message: error.response?.data?.message,
          type: "error",
        });
        return error;
      });
    },

  }
};

export default moduleWorkspace;
