<template>
  <base-nav
    id="company-jumbo-navbar"
    v-model="showMenu"
    class="navbar-absolute top-navbar company-jumbo"
    :style="companyJumboStyle"
    :transparent="true"
  >
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline"><sidebar-toggle-button /></div>
      <div
        class="navbar-toggle d-inline"
        :class="{ toggled: $sidebar.showSidebar }"
      >
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <router-link
        class="navbar-brand logos-wrapper"
        :class="[
          user.extra_info?.company_logo
            ? 'custom-logo-dashboard'
            : 'logo-dashboard',
        ]"
        to="/"
      >
        <img class="company-logo" :src="user.extra_info?.company_logo" alt="" />
      </router-link>
    </div>

    <ul
      class="navbar-nav d-flex navbar-align-left"
      :class="$rtl.isRTL ? 'mr-auto' : 'ml-auto'"
    >
      <div class="search-bar input-group searchbar_dashboard ml-2">
        <el-select
          :popper-append-to-body="false"
          class="select-primary w-100 navbar_placeholer searchBar_products"
          filterable
          size="medium"
          placeholder="Search tool here..."
          v-model="selectedTool"
        >
          <el-option
            v-for="tool in tools"
            :key="tool.code"
            class="select-primary breadcrumb-link"
            :value="tool.slug"
            :label="tool.name"
          >
          </el-option>
        </el-select>
      </div>

      <div
        v-show="hasWorkspace"
        class="search-bar input-group searchbar_dashboard ml-2"
      >
        <el-select
          :popper-append-to-body="false"
          id="workspaceSelect"
          class="select-primary w-100 navbar_placeholer"
          size="medium"
          :value="workspaceSelected.name"
        >
          <router-link
            v-if="userRole != 'user' || inPersonalWorkspace"
            :to="{
              name: 'workspace.settings',
              params: { id: selectedWorkspace?.id },
            }"
            class="breadcrumb-link"
          >
            <el-option
              class="workspace-select-option select-primary text-center"
              value="workspace.settings"
              label="Workspace Settings"
            >
            </el-option>
          </router-link>
          <router-link
            :to="{ name: 'workspace.login' }"
            class="breadcrumb-link"
          >
            <el-option
              class="workspace-select-option switch-workspace select-primary text-center"
              value="workspace.login"
              label="Switch Workspace"
            >
            </el-option>
          </router-link>
        </el-select>
      </div>

      <base-dropdown
        tag="li"
        :menu-on-right="!$rtl.isRTL"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template slot="title">
          <div class="photo">
            <img :src="avatar" />
          </div>
          <label class="ml-2 mr-3 pr-2" style="cursor: pointer">{{
            user?.name ?? "User"
          }}</label>
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p @click="logout" class="d-lg-none navbar-log-out">Log out</p>
        </template>
        <li class="nav-link">
          <router-link
            to="/my-profile"
            class="nav-item dropdown-item nav-option-list"
            >My Profile</router-link
          >
        </li>
        <li
          v-if="userRole != 'user' || user.extra_info.force_control_panel"
          class="nav-link"
        >
          <router-link
            to="/control-panel"
            class="nav-item dropdown-item nav-option-list"
            >Control Panel</router-link
          >
        </li>
        <li class="nav-link">
          <router-link
            to="/user/api"
            class="nav-item dropdown-item nav-option-list"
            >API</router-link
          >
        </li>
        <div class="dropdown-divider"></div>
        <li v-if="isUserImpersonated" class="nav-link">
          <a
            href="/logout"
            @click.prevent="leaveImpersonate"
            class="nav-item dropdown-item nav-option-list"
            >Leave Impersonation</a
          >
        </li>
        <li v-else class="nav-link">
          <a
            href="/logout"
            @click.prevent="logout"
            class="nav-item dropdown-item nav-option-list"
            >Log out</a
          >
        </li>
      </base-dropdown>

      <li class="adjustments-line d-flex align-items-center">
        <div class="togglebutton switch-change-color mt-0">
          <el-switch v-model="darkMode" @change="toggleMode"> </el-switch>
        </div>
        <a @click.prevent="toggleFullScreen()">
          <i class="ml-4 fa-regular fa-expand expand_button"></i>
        </a>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import { BaseNav } from "@/components";
import SidebarToggleButton from "./SidebarToggleButton";
import { Select, Option, Switch } from "element-ui";
import { getDarkMode, setDarkMode } from "../../services/localService";
import { mapGetters } from "vuex";

export default {
  components: {
    SidebarToggleButton,
    BaseNav,
    [Select.name]: Select,
    [Option.name]: Option,
    [Switch.name]: Switch,
  },
  data() {
    return {
      activeNotifications: false,
      faqUrl: this.$t("faqs.faqContacts"),
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      darkMode: getDarkMode(),
      workspaceOptions: [
        { value: "personal", label: "Personal" },
        { value: "business", label: "Business" },
      ],
      languageSelected: "EN",
      languageOptions: [
        { value: "EN" },
        { value: "IT" },
        { value: "DE" },
        { value: "ES" },
        { value: "FR" },
        { value: "BR" },
      ],
      searchTool: "",
      selectedTool: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      avatar: "getAvatar",
      userRole: "getUserRole",
      hasWorkspace: "getHasWorkspace",
      inPersonalWorkspace: "inPersonalWorkspace",
      isUserImpersonated: "getIsUserImpersonated",
      selectedWorkspace: "selectedWorkspace",
    }),
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    isRTL() {
      return this.$rtl.isRTL;
    },
    tools() {
      let ts = this.$store.getters.getTools;
      // hide taskAssignment tool in personal ws
      if (this.$store.getters.selectedWorkspace.id == null) {
        ts = ts.filter((tool) => !tool.has_task_assignment);
      }
      return ts.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
    },
    workspaceSelected() {
      return this.$store.getters.selectedWorkspace;
    },
    companyJumboStyle() {
      return `background-image: url(${this.user.extra_info?.company_jumbo_logo}) !important;`;
    },
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      let bodyElementClasses = document.body.classList;
      let htmlElementClasses = document.documentElement.classList;
      if (!this.$sidebar.showSidebar) {
        bodyElementClasses.add("overflow-no-scroll");
        htmlElementClasses.add("overflow-no-scroll");
      } else {
        bodyElementClasses.remove("overflow-no-scroll");
        htmlElementClasses.remove("overflow-no-scroll");
      }
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleMode(type) {
      setDarkMode(type);
      let docClasses = document.body.classList;
      if (type) {
        docClasses.remove("white-content");
      } else {
        docClasses.add("white-content");
      }
    },
    logout() {
      console.log("logout");
      this.$store.dispatch("logout");
    },
    leaveImpersonate() {
      this.$store.dispatch("leaveImpersonate");
    },
    searchToolByInput() {
      this.$store.commit("searchToolByInput", this.searchTool);
      console.log(this.$store.state.count);
    },

    toggleFullScreen() {
      if (
        (document.fullScreenElement !== undefined &&
          document.fullScreenElement === null) ||
        (document.msFullscreenElement !== undefined &&
          document.msFullscreenElement === null) ||
        (document.mozFullScreen !== undefined && !document.mozFullScreen) ||
        (document.webkitIsFullScreen !== undefined &&
          !document.webkitIsFullScreen)
      ) {
        if (document.documentElement.requestFullScreen) {
          document.documentElement.requestFullScreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullScreen) {
          document.documentElement.webkitRequestFullScreen(
            Element.ALLOW_KEYBOARD_INPUT,
          );
        } else if (document.documentElement.msRequestFullscreen) {
          document.documentElement.msRequestFullscreen();
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    },
  },
  watch: {
    selectedTool() {
      if (this.selectedTool !== "") {
        const isCurrentToolPage = this.selectedTool !== this.$route.params.slug;
        const path = `/tools/${this.selectedTool}`;
        this.selectedTool = "";
        if (isCurrentToolPage) {
          this.$router.push(path);
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
.top-navbar {
  top: 0px;
}

.el-select-dropdown__list {
  padding: 4px 0 10px;
}

.white-content .workspace-select-option {
  color: #004583;
  font-weight: 400;
  padding: 20px;
  line-height: 0px;
  text-align: center;
}

.workspace-select-option.hover {
  font-weight: 700;
  background: transparent !important;
}
.white-content .workspace-select-option.hover {
  color: #004583 !important;
}

.workspace-select-option.switch-workspace {
  border: 1px solid #007bff;
  border-radius: 6px;
  line-height: 0px !important;
  font-weight: 700;
  padding: 20px 0 !important;
  margin: 12px 16px 6px;
  color: white !important;
  border: 1px solid #004583;
  background-color: #004583 !important;
}

.workspace-select-option.switch-workspace.hover {
  color: white !important;
}
.main-logo,
.company-logo {
  max-height: 42px;
}

.logos-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 205px;
}

.expand_button {
  cursor: pointer;
}
#company-jumbo-navbar.company-jumbo {
  height: 300px;
  background-size: cover !important;
  background-position: left center !important;
  display: flex !important;
  align-items: flex-start !important;
}
</style>
