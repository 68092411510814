<template>
  <footer class="footer d-flex justify-content-center">
      <div class="copyright">
        Copyright &copy; {{ year }}
        <a
          href="https://www.contents.com/"
          target="_blank"
          rel="noopener"
          class="font-weight-bold"
          >Contents.com</a
        >
        . All rights reserved
        <p class="text-center font-weight-bold">v2.0</p>
      </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
