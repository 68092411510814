import axios from "@/axios";

export default {
  state: () => ({
    workspaceLinkSourcesLoading: false,
    workspaceLinkSources: [],
  }),
  getters: {
    workspaceLinkSources: (state) => state.workspaceLinkSources,
    workspaceLinkSourcesLoading: (state) => state.workspaceLinkSourcesLoading,
  },
  mutations: {
    setWorkspaceLinkSourcesLoading(state, loading) {
      state.workspaceLinkSourcesLoading = loading;
    },
    setWorkspaceLinkSources(state, workspaceLinkSources) {
      state.workspaceLinkSources = workspaceLinkSources;
    },
    addWorkspaceLinkSource(state, workspaceLinkSource) {
      state.workspaceLinkSources.push(workspaceLinkSource);
    },
  },
  actions: {
    async fetchWorkspaceLinkSources({ commit }, workspaceId) {
      commit("setWorkspaceLinkSourcesLoading", true);
      const response = await axios.get(`features/link-builder/${workspaceId}`);
      const data = response.data;
      console.log(data);
      commit("setWorkspaceLinkSources", data);
      commit("setWorkspaceLinkSourcesLoading", false);
    },

    async createLinkSource({ commit }, linkSource) {
      commit("setWorkspaceLinkSourcesLoading", true);
      const response = await axios.post("features/link-builder", linkSource);
      const data = response.data;
      commit("addWorkspaceLinkSource", data);
      commit("setWorkspaceLinkSourcesLoading", false);
    },

    async updateLinkSource({ commit }, linkSource) {
      commit("setWorkspaceLinkSourcesLoading", true);
      const response = await axios.put(`features/link-builder/${linkSource.id}`, linkSource);
      const data = response.data;
      commit("setWorkspaceLinkSources", data);
      commit("setWorkspaceLinkSourcesLoading", false);
    },

    async deleteLinkSource({ commit, state }, id) {
      commit("setWorkspaceLinkSourcesLoading", true);
      const response = await axios.delete(`features/link-builder/${id}`);
      commit("setWorkspaceLinkSources", state.workspaceLinkSources.filter((source) => source.id !== id));
      commit("setWorkspaceLinkSourcesLoading", false);
    }
  },
};
