import { Exception } from "sass";

export { setCookie, getCookie, deleteCookie, cookies, hideBatchOrderBalloonCookie, showBatchOrderBalloonCookie };

const cookies = {

  COOKIE_TOKEN_KEY: "_entp_bearerToken",
  COOKIE_USER_KEY: "_entp_user",
  
  COOKIE_IMPERSONATE_TOKEN_KEY: "_entp_impersonate_bearerToken",
  COOKIE_IMPERSONATE_USER_KEY: "_entp_impersonate_user",
  
  COOKIE_WORKSPACE: "_entp_workspace",

  COOKIE_BATCH_ORDER_HIDE_BALLOON: "_entp_batch_order_hide_ballon"

};

/**
 * Sets a cookie with the specified name, value, and expiration time.
 *
 * @param {string} name - Name of the cookie.
 * @param {any} value - Value to be stored
 * @param {number} expiration - Expiration time in hours or null for session cookies
 */
const setCookie = (name, value, expiration = null) => {
  // Create a cookie with expire date
  if (expiration) {
    // Calculate the expiration time and convert to UTC format
    const date = new Date();
    date.setTime(date.getTime() + expiration * 60 * 60 * 1000);
    const expires = "expires=" + date.toUTCString();
  
    // Set the cookie with the provided name, JSON-stringified value, expiration, and path
    document.cookie = `${name}=${JSON.stringify(value)}; ${expires}; path=/`;
  }
  else {
    // Create a session cookie
    // It will last for the duration of the session or until the browser is closed
    document.cookie = `${name}=${JSON.stringify(value)}; path=/`;
  }

};


/**
 * Retrieves the value of a cookie by its name.
 *
 * @param {string} name - The name of the cookie to retrieve.
 * @returns {any} The value of the cookie, parsed as JSON if possible, or as a string.
 */
const getCookie = (name) => {
  // Decode the entire cookie string
  const decodedCookie = decodeURIComponent(document.cookie);

  // Split the cookie string into an array removing leading/trailing spaces
  const cookieArray = decodedCookie.split(";").map((cookie) => cookie.trim());

  // Find the desired cookie by its name
  const selectedCookie = cookieArray.find(
    (cookie) => cookie.indexOf(name) === 0
  );

  if (selectedCookie) {
    const cookieValue = selectedCookie.substring(name.length + 1);

    // Try parsing the cookie value as JSON or return as a string
    try {
      return JSON.parse(cookieValue);
    } catch (error) {
      return cookieValue;
    }
  }

  return null;
};


/**
 * Deletes a cookie by setting its expiration date to a date in the past.
 *
 * @param {string} name - The name of the cookie to delete.
 */
const deleteCookie = (name) => {
  // Set the cookie's expiration date to a date in the past
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};


/**
 * Saves in the cookies the batch orders' ballons to be hidden
 * 
 * @param {string} slug   tool's slug
 * @param {string} orderType  single or batch
 */
const hideBatchOrderBalloonCookie = (slug, orderType) => {
  if (orderType !== 'single' && orderType !== 'batch') {
    throw new Exception('Invalid orderType param, only "single" and "batch" values are admitted');
  } 

  const hiddenBalloons = getCookie(cookies.COOKIE_BATCH_ORDER_HIDE_BALLOON) ?? {};

  if (!hiddenBalloons.hasOwnProperty(slug)) {
    hiddenBalloons[slug] = [];
  }

  if (hiddenBalloons[slug].indexOf(orderType) === -1) {
    hiddenBalloons[slug].push(orderType)
  }

  console.log('hiddenBalloons', hiddenBalloons);

  setCookie(cookies.COOKIE_BATCH_ORDER_HIDE_BALLOON, hiddenBalloons);
}


/**
 * Returns the visibility status of batch order balloon based on the provided slug
 * 
 * @param {string} slug 
 * @returns {Object}
 */
const showBatchOrderBalloonCookie = (slug) => {
  const ballonCookie = getCookie('_entp_batch_order_hide_ballon');

  if (!ballonCookie || !ballonCookie.hasOwnProperty(slug)) {
    return {
      'single': true,
      'batch': true
    }
  }

  return {
    'single': !ballonCookie[slug].includes('single') ?? true,
    'batch': !ballonCookie[slug].includes('batch') ?? true
  }
}