import axios from "@/axios.js";

const moduleLinkBuilderGeneric = {
  state: {
    editingArea: null,
    settings: {
      source: [],
      apply: "all",
    },
    selection: null,
    keyword: null,
    occurrences: [],
    custom_url: null,
    errors: {
      keyword: null,
      custom_url: null
    },
    suggestedLinksLoading: false,
    suggestedLinks: [],
    activeLinks: [],
  },
  getters: {
    getKeyword(state) {
      return state.keyword;
    },
    getOccurrences(state) {
      return state.occurrences;
    },
    getOccurrencesNumber(state) {
      return state.occurrences.length;
    },
    getSource(state) {
      return state.settings.source;
    },
    getApply(state) {
      return state.settings.apply;
    },
    getSelection(state) {
      return state.selection;
    },
    getCustomUrl(state) {
      return state.custom_url;
    },
    getErrors(state) {
      return state.errors;
    },
    anyError(state) {
      return Object.values(state.errors).some((error) => error !== null);
    },
    getSuggestedLinks(state) {
      return state.suggestedLinks;
    },
    getSuggestedLinksLoading(state) {
      return state.suggestedLinksLoading;
    },
    getEditingArea(state) {
      return state.editingArea;
    },
    getActiveLinksInEditingArea(state) {
      let activeLinks = [];
      const links = state.editingArea.querySelectorAll("a");
      links.forEach((link) => {
        if(activeLinks.length > 0 && activeLinks.find((activeLink) => activeLink.href === link.href)) {
          activeLinks.find((activeLink) => activeLink.href === link.href).occurrences++;
          return;
        }
        activeLinks.push({
          href: link.href,
          text: link.innerText,
          occurrences: 1
        })
      });
      return activeLinks;
    }
  },
  mutations: {
    setSource(state, source) {
      state.settings.source = source;
    },
    setApply(state, apply) {
      state.settings.apply = apply;
    },
    setSelection: (state, selection) => {
      state.selection = selection;
    },
    setKeyword: (state, keyword) => {
      if (keyword == null) {
        state.errors.keyword = null;
      } else if (keyword.match(/^[\s.,;!?]*$/)) {
        state.errors.keyword = "Keyword is required";
      } else {
        state.errors.keyword = null;
      }
      state.keyword = keyword;
    },
    setOccurrences: (state, occurrences) => {
      state.occurrences = occurrences;
    },
    setLinkOnSelection: (state, url) => {
      if (!state.selection) return;
      const anchor = document.createElement("a");
      anchor.href = url;
      state.selection.surroundContents(anchor);
    },
    setLinkOnWholeText: (state, url) => {
      state.occurrences.forEach((occurrence) => {
        if(typeof occurrence.href === "undefined") {
          const anchor = document.createElement("a");
          anchor.href = url;
          occurrence.surroundContents(anchor);
        } else {
          occurrence.href = url
        }
      });
    },
    setCustomUrl: (state, url) => {
      if (!url || !url.match(/^(http|https):\/\/[^ "]+$/)) {
        state.errors.custom_url = "Enter a valid URL";
      } else {
        state.errors.custom_url = null;
      }
      state.custom_url = url;
    },
    setSuggestedLinksLoading: (state, loading) => {
      state.suggestedLinksLoading = loading;
    },
    setSuggestedLinks: (state, suggestedLinks) => {
      state.suggestedLinks = suggestedLinks;
    },
    setEditingArea: (state, editingArea) => {
      state.editingArea = editingArea;
    },
  },
  actions: {
    setLink({ commit, state }, url) {
      if (state.settings.apply === "all") {
        this.commit("setLinkOnWholeText", url);
      } else {
        this.commit("setLinkOnSelection", url);
      }
    },
    updateKeyword({ commit, state }, keyword) {
      if(state.keyword === keyword) return;
      if(!keyword) {
        commit("setKeyword", null);
        return;
      }
      if(state.selection === null) {
        commit("setApply", "all")
      }
      commit("setKeyword", keyword);
    },
    async fetchWorkspaceLinkResult({ commit }, { workspaceId, keyword, source } = []) {
      commit("setSuggestedLinks", []);
      commit("setSuggestedLinksLoading", true);
      const response = await axios.post(`features/link-builder/search`, {
        workspace_id: workspaceId,
        keyword: keyword,
        source_ids: source
      });
      const data = response.data;
      commit("setSuggestedLinks", data);
      commit("setSuggestedLinksLoading", false);
    }
  },
};

export default moduleLinkBuilderGeneric;
