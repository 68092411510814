<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log("beforerouteenter", vm.$store.getters.isUserLoggedIn);
    });
  },
};
</script>
