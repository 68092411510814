import axios from "axios";
import config from "./config";
import store from '@/store/store';

const instance = axios.create({
  baseURL: config.baseURL,
});

instance.interceptors.request.use(function (config) {
  let bearerToken = store.getters.getToken ?? null;
  config.headers.Authorization = (bearerToken) ? `Bearer ` + bearerToken : '';

  if (config.data) {
    if (config.data instanceof FormData) {
      config.data.append('source', 'enterprise');
    } else {
      config.data.source = "enterprise";
    }
  }

  if (config.params) {
    config.params.source = "enterprise";
  }

  return config;
});

export default instance;
