<template>
  <div class="form-group" :class="{
    'input-group-focus': focused,
    'has-label': label,
    'has-icon': hasIcon,
  }">
    <slot name="label">
      <label v-if="label">
        {{ label }} {{ required ? '*' : '' }}
        <span v-if="optional" class="optional">(Optional)</span>
        <el-tooltip v-if="tooltip" :content="tooltip" placement="top">
          <i class="fa-info-circle fa-solid ml-2"></i>
        </el-tooltip>
      </label>
    </slot>
    <div class="mb-0" :class="{ 'input-group': hasIcon }">
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot>
        <textarea @input="stopResize ? '' : resize()" ref="textarea" :style="setStyle" :name="name" :value="value" id=""
          v-bind="$attrs" v-on="listeners" class="form-control closed" aria-describedby="addon-right addon-left"
          cols="30" rows="20"></textarea>
      </slot>
      <slot name="addonRight">
        <span v-if="addonRightIcon" class="input-group-append">
          <div class="input-group-text"><i :class="addonRightIcon"></i></div>
        </span>
      </slot>
    </div>

    <slot name="error" v-if="error || $slots.error">
      <label class="error">{{ error }}</label>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'base-textarea',
  props: {
    required: Boolean,
    label: {
      type: String,
      description: 'Input label'
    },
    optional: {
      type: Boolean,
      description: 'Optional label'
    },
    error: {
      type: String,
      description: 'Input error',
      default: ''
    },
    tooltip: {
      type: String,
      description: 'Informative Message',
      default: null
    },
    value: {
      type: [String, Number],
      description: 'Input value'
    },
    name: {
      type: String,
      description: 'Input name'
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right'
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left'
    },
    setStyle: {
      type: Object,
      default: () => ({})
    },
    stopResize: {
      type: Boolean,
      default: false
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  data() {
    return {
      focused: false,
      touched: false
    };
  },
  computed: {
    hasIcon() {
      return this.hasLeftAddon || this.hasRightAddon
    },
    hasLeftAddon() {
      const { addonLeft } = this.$slots;
      return (
        addonLeft !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    hasRightAddon() {
      const { addonRight } = this.$slots;
      return (
        addonRight !== undefined ||
        this.addonRightIcon !== undefined
      );
    },
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus
      };
    },
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt.target.value);
    },
    onFocus(evt) {
      this.focused = true;
      this.$emit('focus', evt)
    },
    onBlur(evt) {
      this.focused = false;
      this.$emit('blur', evt)
    },
    resize() {
      let element = this.$refs["textarea"];
      element.classList.remove('closed');
      element.style.height = "18px";
      element.style.height = element.scrollHeight + "px";
      element.style.maxHeight = '120px';
      element.style.minHeight = '80px';
    },

  },
  mounted() {

  }
};
</script>
<style>
textarea::-webkit-scrollbar {
  display: none;
}

.textarea {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.base-textarea__label {
  color: #336A9C;
  font-size: 12px !important;
  font-weight: 700 !important;
  line-height: 14px;
  margin-bottom: 0.5rem !important;
  display: inline-block;
}
</style>
